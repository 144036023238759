@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar{
    width:10px;

}
::-webkit-scrollbar-track{
    background-color:#1B1F24;

}
::-webkit-scrollbar-thumb{
    background-color:rgb(49,57,65);
    border-radius:50px;
    height:10rem;
}

-moz-scrollbar{
    width:10px;

}
-moz-scrollbar-track{
    background-color:#1B1F24;

}
-moz-scrollbar-thumb{
    background-color:rgb(49,57,65);
    border-radius:50px;
    height:10rem;
}

.mobileno{
position:relative;
}
.mobileno:before{
content:"";
position: absolute;
left:0;
bottom:-10px;
width:100%;
height:3px;
background-color:#8895AA;

}
.mobileno::after{
content:"";
position: absolute;
left:0;
bottom:-10px;
width:0%;
height:3px;
background-color:white;
transition:width 0.25s ease-in-out;
}
.mobileno:hover::after{
    width:100%;
}
.emailaddress{
position:relative;
}
.emailaddress:before{
content:"";
position: absolute;
left:0;
bottom:-10px;
width:100%;
height:3px;
background-color:#8895AA;

}
.emailaddress::after{
content:"";
position: absolute;
left:0;
bottom:-10px;
width:0%;
height:3px;
background-color:white;
transition:width 0.25s ease-in-out;
}
.emailaddress:hover::after{
    width:100%;
}

.bodyButton{
transition: 0.25s ease-in-out;
height:3rem;

}
.bodyButton:hover{
    box-shadow: inset 15rem 0 0 0  white;
    color:black

}
.resumeHeader{
transition: 0.25s ease-in-out;
height:3rem;

}
.resumeHeader:hover{
    box-shadow: inset 15rem 0 0 0  white;
    color:black !important

}
